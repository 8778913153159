<template>
  <div>
    <v-container>
      <v-row class="mb-8">
        <v-col lg="12">
          <v-card-title>
            <h2>Chart Of Accounts</h2>
            <!--Skeleton button-->
            <v-skeleton-loader
              :loading="loading_user"
              type="button"
              v-if="firstLoad"
              class="ml-10"
            >
            </v-skeleton-loader>
            <AddAccountButton
              v-if="!firstLoad"
              v-show="this.user_roles_list.flat().includes('accounting_write')"
              :chart_of_accounts="
                chart_of_accounts.filter(
                  (item) => item.account_name === 'Facebook Ads'
                )
              "
              :modalType="'new'"
            />
          </v-card-title>
        </v-col>
      </v-row>
      <!--Skeleton loader-->
      <v-row v-if="firstLoad">
        <v-col>
          <v-sheet elevation="4">
            <v-skeleton-loader
              :loading="loading_user"
              type="table"
              max-height="500"
            >
            </v-skeleton-loader>
          </v-sheet>
        </v-col>
      </v-row>
      <!--End of skeleton loader-->
      <v-row
        v-if="!firstLoad"
        v-show="this.user_roles_list.flat().includes('banking_read')"
      >
        <v-col>
          <v-sheet elevation="4">
            <v-data-table
              :headers="accountHeaders"
              :items="chart_of_accounts.filter((item) => !item.parent_account)"
              :items-per-page="100"
              group-by="account_type"
              :sort-by="['account_sub_type', 'account_name']"
              :sort-desc="[false, false]"
              multi-sort
              dense
              show-select
              single-select
              item-key="account_id"
              show-expand
            >
              <!--Group header-->
              <template v-slot:group.header="{ items }">
                <th colspan="9" class="blue-grey lighten-5">
                  {{ items[0].account_type }}
                </th>
              </template>
              <!--Edit icon-->
              <template v-slot:item.data-table-select="props">
                <span v-if="props.item.locked != true">
                  <v-btn
                    @click.stop="
                      $set(editAccountDialog, props.item.account_id, true);
                      getJournalEntries(props.item.account_id);
                    "
                    icon
                    small
                    left
                    color="black"
                    ><v-icon small>mdi-pencil</v-icon></v-btn
                  >
                  <v-dialog
                    v-model="editAccountDialog[props.item.account_id]"
                    scrollable
                    max-width="700"
                    :key="props.item.account_id"
                  >
                    <NewAccountModal
                      :chart_of_accounts="chart_of_accounts"
                      :modalType="'edit'"
                      :value="props.item"
                      :filtered_entries="filtered_entries"
                    />
                  </v-dialog>
                </span>

                <!--Locked accounts-->
                <v-btn v-else icon disabled left small>
                  <v-icon small>mdi-lock</v-icon>
                </v-btn>
              </template>

              <!--Expand to view sub-ledgers-->
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="px-0 pl-10">
                  <v-data-table
                    class="blue-grey lighten-5"
                    :headers="accountHeaders"
                    :items="
                      chart_of_accounts.filter(
                        (i) =>
                          i.parent_account &&
                          i.parent_account.account_name === item.account_name
                      )
                    "
                    :items-per-page="1000"
                    hide-default-footer
                    show-select
                    single-select
                  >
                    <!--Edit icon-->
                    <template v-slot:item.data-table-select="props">
                      <span v-if="props.item.locked != true">
                        <v-btn
                          @click.stop="
                            $set(
                              editAccountDialog,
                              props.item.account_id,
                              true
                            );
                            getJournalEntries(props.item.account_id);
                          "
                          icon
                          small
                          left
                          color="black"
                          ><v-icon small>mdi-pencil</v-icon></v-btn
                        >
                        <v-dialog
                          v-model="editAccountDialog[props.item.account_id]"
                          scrollable
                          max-width="700"
                          :key="props.item.account_id"
                        >
                          <NewAccountModal
                            :chart_of_accounts="chart_of_accounts"
                            :modalType="'edit'"
                            :value="props.item"
                            :filtered_entries="filtered_entries"
                          />
                        </v-dialog>
                      </span>

                      <!--Locked accounts-->
                      <v-btn v-else icon disabled left small>
                        <v-icon small>mdi-lock</v-icon>
                      </v-btn>
                    </template>
                  </v-data-table>
                </td>
              </template>

              <template
                v-slot:item.data-table-expand="{ expand, isExpanded, item }"
              >
                <v-icon
                  v-if="item.sub_ledgers && item.sub_ledgers.length > 0"
                  @click="expand(!isExpanded)"
                  >mdi-chevron-down</v-icon
                >
              </template>
            </v-data-table>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import db from "../../../components/firebaseInit";
import mixin_UserRoles from "../../../globalActions/mixin_UserRoles";
import { getJournalEntries } from "../data/external_journal_entries";
export default {
  name: "ChartOfAccountsTable",
  components: {
    AddAccountButton: () => import("./AddAccountButton"),
    NewAccountModal: () => import("./NewAccountModal"),
  },
  mixins: [mixin_UserRoles],
  data() {
    return {
      chart_of_accounts: [],
      modalType: null,
      editAccountDialog: {},
      componentKey: 0,
      journal_entries: [],
      filtered_entries: [],
      accountHeaders: [
        { text: "Code", value: "account_code", align: "start" },
        { text: "Name", value: "account_name" },
        { text: "Type", value: "account_type" },
        { text: "Category", value: "account_sub_type" },
        { text: "Financial Statement", value: "financial_statement" },
        { text: "Status", value: "account_status" },
        { text: "", value: "data-table-expand" },
      ],
    };
  },
  created() {
    this.getAccountsData();
  },
  methods: {
    getJournalEntries,
    //  Get accounts from database
    getAccountsData() {
      db.collection("chart_of_accounts").onSnapshot((querySnapshot) => {
        this.chart_of_accounts = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          this.chart_of_accounts.push(data);
          this.chart_of_accounts.sort((a, b) =>
            a.account_code > b.account_code ? 1 : -1
          );
        });
      });
    },
  },
};
</script>