var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('v-row',{staticClass:"mb-8"},[_c('v-col',{attrs:{"lg":"12"}},[_c('v-card-title',[_c('h2',[_vm._v("Chart Of Accounts")]),(_vm.firstLoad)?_c('v-skeleton-loader',{staticClass:"ml-10",attrs:{"loading":_vm.loading_user,"type":"button"}}):_vm._e(),(!_vm.firstLoad)?_c('AddAccountButton',{directives:[{name:"show",rawName:"v-show",value:(this.user_roles_list.flat().includes('accounting_write')),expression:"this.user_roles_list.flat().includes('accounting_write')"}],attrs:{"chart_of_accounts":_vm.chart_of_accounts.filter(
                function (item) { return item.account_name === 'Facebook Ads'; }
              ),"modalType":'new'}}):_vm._e()],1)],1)],1),(_vm.firstLoad)?_c('v-row',[_c('v-col',[_c('v-sheet',{attrs:{"elevation":"4"}},[_c('v-skeleton-loader',{attrs:{"loading":_vm.loading_user,"type":"table","max-height":"500"}})],1)],1)],1):_vm._e(),(!_vm.firstLoad)?_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(this.user_roles_list.flat().includes('banking_read')),expression:"this.user_roles_list.flat().includes('banking_read')"}]},[_c('v-col',[_c('v-sheet',{attrs:{"elevation":"4"}},[_c('v-data-table',{attrs:{"headers":_vm.accountHeaders,"items":_vm.chart_of_accounts.filter(function (item) { return !item.parent_account; }),"items-per-page":100,"group-by":"account_type","sort-by":['account_sub_type', 'account_name'],"sort-desc":[false, false],"multi-sort":"","dense":"","show-select":"","single-select":"","item-key":"account_id","show-expand":""},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
              var items = ref.items;
return [_c('th',{staticClass:"blue-grey lighten-5",attrs:{"colspan":"9"}},[_vm._v(" "+_vm._s(items[0].account_type)+" ")])]}},{key:"item.data-table-select",fn:function(props){return [(props.item.locked != true)?_c('span',[_c('v-btn',{attrs:{"icon":"","small":"","left":"","color":"black"},on:{"click":function($event){$event.stopPropagation();_vm.$set(_vm.editAccountDialog, props.item.account_id, true);
                    _vm.getJournalEntries(props.item.account_id);}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1),_c('v-dialog',{key:props.item.account_id,attrs:{"scrollable":"","max-width":"700"},model:{value:(_vm.editAccountDialog[props.item.account_id]),callback:function ($$v) {_vm.$set(_vm.editAccountDialog, props.item.account_id, $$v)},expression:"editAccountDialog[props.item.account_id]"}},[_c('NewAccountModal',{attrs:{"chart_of_accounts":_vm.chart_of_accounts,"modalType":'edit',"value":props.item,"filtered_entries":_vm.filtered_entries}})],1)],1):_c('v-btn',{attrs:{"icon":"","disabled":"","left":"","small":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-lock")])],1)]}},{key:"expanded-item",fn:function(ref){
                    var headers = ref.headers;
                    var item = ref.item;
return [_c('td',{staticClass:"px-0 pl-10",attrs:{"colspan":headers.length}},[_c('v-data-table',{staticClass:"blue-grey lighten-5",attrs:{"headers":_vm.accountHeaders,"items":_vm.chart_of_accounts.filter(
                      function (i) { return i.parent_account &&
                        i.parent_account.account_name === item.account_name; }
                    ),"items-per-page":1000,"hide-default-footer":"","show-select":"","single-select":""},scopedSlots:_vm._u([{key:"item.data-table-select",fn:function(props){return [(props.item.locked != true)?_c('span',[_c('v-btn',{attrs:{"icon":"","small":"","left":"","color":"black"},on:{"click":function($event){$event.stopPropagation();_vm.$set(
                            _vm.editAccountDialog,
                            props.item.account_id,
                            true
                          );
                          _vm.getJournalEntries(props.item.account_id);}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1),_c('v-dialog',{key:props.item.account_id,attrs:{"scrollable":"","max-width":"700"},model:{value:(_vm.editAccountDialog[props.item.account_id]),callback:function ($$v) {_vm.$set(_vm.editAccountDialog, props.item.account_id, $$v)},expression:"editAccountDialog[props.item.account_id]"}},[_c('NewAccountModal',{attrs:{"chart_of_accounts":_vm.chart_of_accounts,"modalType":'edit',"value":props.item,"filtered_entries":_vm.filtered_entries}})],1)],1):_c('v-btn',{attrs:{"icon":"","disabled":"","left":"","small":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-lock")])],1)]}}],null,true)})],1)]}},{key:"item.data-table-expand",fn:function(ref){
                          var expand = ref.expand;
                          var isExpanded = ref.isExpanded;
                          var item = ref.item;
return [(item.sub_ledgers && item.sub_ledgers.length > 0)?_c('v-icon',{on:{"click":function($event){return expand(!isExpanded)}}},[_vm._v("mdi-chevron-down")]):_vm._e()]}}],null,false,1929980960)})],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }